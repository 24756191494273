<template>
  <FocusTrap>
    <!-- Page content -->
    <div class="page-content">

      <!-- Main navbar -->
      <div class="navbar navbar-expand-md navbar-dark bg-indigo-800 fixed-top">
        <div class="navbar-brand">
          <a href="/" class="d-inline-block">
            <img src="static/global_assets/images/logo_light.png" alt="LMS">
          </a>
        </div>

        <div class="d-md-none">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
            <i class="icon-tree5"></i>
          </button>
          <button class="navbar-toggler sidebar-mobile-main-toggle" type="button">
            <i class="icon-paragraph-justify3"></i>
          </button>
        </div>

        <div class="collapse navbar-collapse" id="navbar-mobile">

          <ul class="navbar-nav ml-auto" v-if="loggedin">
            <li class="nav-item">
            <a href="#" class="navbar-nav-link" >
              {{welcomemsg}}
            </a>
            </li>


            <li class="nav-item dropdown" >
              <a href="#" class="navbar-nav-link">
                <i class="icon-bell2"></i>
                <span class="d-md-none ml-2">Notifications</span>
                <span class="badge badge-mark border-white ml-auto ml-md-0"></span>
              </a>
            </li>

            <li class="nav-item dropdown dropdown-user" >
              <a href="#" class="navbar-nav-link d-flex align-items-center dropdown-toggle"
                 data-toggle="dropdown">
                <img src="static/global_assets/images/image.png" class="rounded-circle mr-2" height="34" alt="">
                <span>{{username}}</span>
              </a>

              <div class="dropdown-menu dropdown-menu-right">
                <!--<router-link class="dropdown-item" to="/messages"><i class="icon-comment-discussion"></i>-->
                  <!--Messages <span class="badge badge-pill bg-blue ml-auto">1</span></router-link>-->
                <!--<div class="dropdown-divider"></div>-->
                <router-link class="dropdown-item" to="/login"><i class="icon-switch2"></i> Logout
                </router-link>
              </div>
            </li>

          </ul>
        </div>
      </div>
      <!-- /main navbar -->

      <!-- Main sidebar -->
      <div class="sidebar sidebar-light sidebar-main sidebar-fixed sidebar-expand-md">

        <!-- Sidebar mobile toggler -->
        <div class="sidebar-mobile-toggler text-center">
          <a href="#" class="sidebar-mobile-main-toggle">
            <i class="icon-arrow-left8"></i>
          </a>
          Navigation
          <a href="#" class="sidebar-mobile-expand">
            <i class="icon-screen-full"></i>
            <i class="icon-screen-normal"></i>
          </a>
        </div>
        <!-- /sidebar mobile toggler -->


        <!-- Sidebar content -->
        <div class="sidebar-content">


          <!-- Main navigation -->
          <div class="card card-sidebar-mobile">
            <ul class="nav nav-sidebar" data-nav-type="accordion">

              <!-- Main -->
              <li class="nav-item">
                <router-link to="/search_book" class="nav-link">
                  <i class="icon-search4"></i>
                  <span>Search</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link to="/dashboard" class="nav-link">
                  <i class="icon-home4"></i>
                  <span>Dashboard</span>
                </router-link>
              </li>
              <!-- /main -->

              <!-- Office -->
              <li class="nav-item nav-item-submenu">
                <a href="#" class="nav-link"><i class="icon-puzzle2"></i> <span>Circulation</span></a>

                <ul class="nav nav-group-sub" data-submenu-title="Circulation">
                  <li class="nav-item"><router-link class="nav-link" to="/newissue">New Issue</router-link></li>
                  <li class="nav-item"><router-link class="nav-link" to="/openissue">Open Issue List</router-link></li>
                  <li class="nav-item-divider"></li>
                  <li class="nav-item"><router-link class="nav-link" to="/memberdue">Member Due Verification</router-link></li>
                  <li v-if="false" class="nav-item"><router-link class="nav-link" to="/newreceipt">New Return</router-link></li>
                  <li v-if="false" class="nav-item"><router-link class="nav-link" to="/openreceipt">Open Return List</router-link></li>
                </ul>
              </li>
              <!--/Office -->

              <!-- Resources -->
              <li class="nav-item">
                <router-link to="/resource_add" class="nav-link">
                  <i class="icon-book3"></i>
                  <span>Resources</span>
                </router-link>
              </li>
              <!--/Resources -->


              <!-- reports -->
              <li class="nav-item-header">
              <div class="text-uppercase font-size-xs line-height-xs">Reports</div>
              <i class="icon-menu" title="Reports"></i></li>
              <li class="nav-item nav-item-submenu">
                <a href="#" class="nav-link"><i class="icon-stack"></i> <span>Reports</span></a>


                <ul class="nav nav-group-sub" data-submenu-title="Reports">

                  <li class="nav-item nav-item-submenu">
                    <a href="#" class="nav-link">Books</a>
                    <ul class="nav nav-group-sub">
                      <li class="nav-item"><router-link class="nav-link" to="/report_book_general">General</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/report_book_acquisition">Accquistion</router-link></li>
                      <li class="nav-item-divider"></li>
                      <li class="nav-item"><router-link class="nav-link" to="/report_book_acquisition_statistics">Acquisition Statistics</router-link></li>
                    </ul>
                  </li>

                  <li class="nav-item nav-item-submenu">
                    <a href="#" class="nav-link">Members</a>
                    <ul class="nav nav-group-sub">
                      <li class="nav-item"><router-link class="nav-link" to="/report_student_list">Student List</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/report_staff_list">Staff List</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/department_wise_student">Department Wise Student</router-link></li>
                    </ul>
                  </li>

                  <li class="nav-item nav-item-submenu">
                    <a href="#" class="nav-link">Periodicals</a>
                    <ul class="nav nav-group-sub">
                      <li class="nav-item"><router-link class="nav-link" to="/report_periodical_general">General</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/report_periodical_statistics">Statistics</router-link></li>
                    </ul>
                  </li>

                  <li class="nav-item nav-item-submenu">
                    <a href="#" class="nav-link">Circulations</a>
                    <ul class="nav nav-group-sub">
                      <li class="nav-item"><router-link class="nav-link" to="/report_resource_issues">Issues</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/report_resource_returns">Returns</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/report_resource_issuesreturns">Issues & Returns</router-link></li>
                      <li class="nav-item-divider"></li>
                      <li class="nav-item"><router-link class="nav-link" to="/report_resource_overdue">Over Due Statement</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/report_resource_outstanding">Outstanding List</router-link></li>
                    </ul>
                  </li>

                  <li class="nav-item-divider"></li>
                  <li class="nav-item"><router-link class="nav-link" to="/report_backvolume_general">Back Volumes</router-link></li>
                  <li class="nav-item"><router-link class="nav-link" to="/report_nonbook_general">Non-Books</router-link></li>
                  <li class="nav-item-divider"></li>
                  <li class="nav-item"><router-link class="nav-link" to="/student_list">Fine Collections</router-link></li>
                  <li class="nav-item"><router-link class="nav-link" to="/report_maximum_users">Maximum Users</router-link></li>
                  <li class="nav-item-divider"></li>

                  <li v-if="false" class="nav-item nav-item-submenu">
                    <a href="#" class="nav-link">Entry Registers</a>
                    <ul class="nav nav-group-sub">
                      <li class="nav-item"><router-link class="nav-link" to="/student_list">Current Members</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/staff_list">Academic Members</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/staff_list">Reference Section Attendance</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/staff_list">Others</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/staff_list">Entry Register Chart</router-link></li>
                    </ul>
                  </li>


                  <li  v-if="false" class="nav-item nav-item-submenu">
                    <a href="#" class="nav-link">Logs</a>
                    <ul class="nav nav-group-sub">
                      <li class="nav-item"><router-link class="nav-link" to="/member_list">Circulation</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/member_list">Return</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/member_list">Renewal</router-link></li>
                    </ul>
                  </li>

                </ul>
              </li>
              <!--/reports -->

              <!-- Master -->
              <li class="nav-item-header">
                <div class="text-uppercase font-size-xs line-height-xs">Master</div>
                <i class="icon-menu" title="Master"></i>
              </li>
              <li class="nav-item nav-item-submenu">
                <a href="#" class="nav-link"><i class="icon-gear"></i> <span>Master</span></a>

                <ul class="nav nav-group-sub" data-submenu-title="Master">

                  <li class="nav-item nav-item-submenu">
                    <a href="#" class="nav-link">Members</a>
                    <ul class="nav nav-group-sub">
                      <li class="nav-item"><router-link class="nav-link" to="/member_student">Students</router-link></li>
                      <li class="nav-item-divider"></li>
                      <li class="nav-item"><router-link class="nav-link" to="/member_staff">Staffs</router-link></li>
                    </ul>
                  </li>

                  <li class="nav-item nav-item-submenu">
                    <a href="#" class="nav-link">Resources</a>
                    <ul class="nav nav-group-sub">

                      <li class="nav-item"><router-link class="nav-link" to="/book_add">Add Book</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/booklist">Book List</router-link></li>
                      <li class="nav-item-divider"></li>
                      <li class="nav-item"><router-link class="nav-link" to="/book_group">Group</router-link></li>
                      <li class="nav-item-divider"></li>
                      <li class="nav-item"><router-link class="nav-link" to="/book_category">Category</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/book_publisher">Publication</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/book_vendor">Vendor</router-link></li>
                      <li class="nav-item-divider"></li>
                      <li class="nav-item"><router-link class="nav-link" to="/book_author">Author</router-link></li>
                    </ul>
                  </li>

                  <li class="nav-item nav-item-submenu">
                    <a href="#" class="nav-link">Academic</a>
                    <ul class="nav nav-group-sub">
                      <li class="nav-item"><router-link class="nav-link" to="/department">Department</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/subject">Subject</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/section">Section</router-link></li>
                      <li class="nav-item-divider"></li>
                      <li class="nav-item"><router-link class="nav-link" to="/academicyear">Academic Year</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/calendar">Holidays</router-link></li>
                    </ul>
                  </li>

<!--                  <li class="nav-item nav-item-submenu">-->
<!--                    <a href="#" class="nav-link">Human Resource</a>-->
<!--                    <ul class="nav nav-group-sub">-->
<!--                      <li class="nav-item"><router-link class="nav-link" to="/hr-emp">Employee</router-link></li>-->
<!--                      <li class="nav-item-divider"></li>-->
<!--                      <li class="nav-item"><router-link class="nav-link" to="/hr-designation">Designation</router-link></li>-->
<!--                      <li class="nav-item"><router-link class="nav-link" to="/calendar">Holidays</router-link></li>-->
<!--                    </ul>-->
<!--                  </li>-->

                  <li class="nav-item nav-item-submenu">
                    <a href="#" class="nav-link">User</a>
                    <ul class="nav nav-group-sub">
                      <li class="nav-item"><router-link class="nav-link" to="/users">User</router-link></li>
                      <li class="nav-item-divider"></li>
                      <li class="nav-item"><router-link class="nav-link" to="/user_roles">Roles</router-link></li>
                      <li class="nav-item"><router-link class="nav-link" to="/user_permissions">Permission</router-link></li>
                    </ul>
                  </li>

                  <li class="nav-item"><router-link class="nav-link" to="/library">Libraries</router-link></li>
                  <li class="nav-item"><router-link class="nav-link" to="/settings">Settings</router-link></li>


                </ul>
              </li>
              <!--/Master -->

            <!--Users-->
              <li class="nav-item nav-item-submenu">
                <a href="#" class="nav-link"><i class="icon-users"></i> <span>Users</span></a>
                <ul class="nav nav-group-sub" data-submenu-title="Users">
                  <li class="nav-item"><router-link class="nav-link" to="/profile">Profile</router-link></li>
                  <li class="nav-item"><router-link class="nav-link" to="/onlineusers">Online Users</router-link></li>
                </ul>
              </li>
            <!--Users-->

              <!-- logout -->
              <li class="nav-item-header">
                <div class="text-uppercase font-size-xs line-height-xs">Logout</div>
                <i class="icon-menu" title="Logout"></i>
              </li>

              <router-link class="dropdown-item" to="/login"><i class="icon-switch2"></i> Logout </router-link>
              <!--/logout -->

            </ul>
          </div>
          <!-- /main navigation -->

        </div>
        <!-- /sidebar content -->

      </div>
      <!-- /main sidebar -->

      <!-- Main content -->
      <div class="content-wrapper">

        <!-- Page header -->
        <div v-if="pageheader_visible" class="page-header page-header-light">


          <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
            <div class="d-flex">
              <div class="breadcrumb">
                <a href="index.html" class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Home</a>
                <a href="#" class="breadcrumb-item">Link</a>
                <span class="breadcrumb-item active">Current</span>
              </div>

              <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
            </div>

            <div class="header-elements d-none">
              <div class="breadcrumb justify-content-center">
                <a href="#" class="breadcrumb-elements-item">
                  Link
                </a>

                <div class="breadcrumb-elements-item dropdown p-0">
                  <a href="#" class="breadcrumb-elements-item dropdown-toggle" data-toggle="dropdown">
                    Dropdown
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item">Action</a>
                    <a href="#" class="dropdown-item">Another action</a>
                    <a href="#" class="dropdown-item">One more action</a>
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item">Separate action</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /page header -->


        <!-- Content area -->
        <div id="mycontent" class="content" style="padding: 5px;">
              <router-view/>
        </div>
        <!-- /content area -->


        <!-- Footer -->
        <!--<div class="navbar navbar-expand-lg navbar-light fixed-bottom">-->
        <div  id="appfooter" class="navbar navbar-expand-lg navbar-light " tabindex="-1">
          <div class="text-center d-lg-none w-100">
            <button type="button" class="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
              <i class="icon-unfold mr-2"></i>
              Footer
            </button>
          </div>

          <div class="navbar-collapse collapse" id="navbar-footer" tabindex="-1">
              <span class="navbar-text" > &copy; {{myyear}} <a href="#" tabindex="-1">Limitless </a> by <a href="#" tabindex="-1" target="_blank">Py Labs</a>
              </span>
          </div>
        </div>
        <!-- /footer -->

        <!-- Validation form -->
        <div id="modal-validation" class="modal fade" tabindex="-1">
          <div class="modal-dialog modal-sm modal-dialog-centered">
            <div class="modal-content">

              <!-- Form -->
              <form class="modal-body form-validate" action="#">
                <div class="text-center mb-3">
                  <i class="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
                  <h5 class="mb-0">Choose your Branch</h5>
                  <span class="d-block text-muted">& Financial Year</span>
                </div>

                <div class="form-group form-group-feedback form-group-feedback-left">
                  <select id="cmbbranch" class="form-control" required autofocus v-model="branch.code">
                    <option v-for="brn in branches" v-bind:value="brn.code">
                      {{ brn.name }}
                    </option>
                  </select>
                  <div class="form-control-feedback">
                    <i class="icon-box text-muted"></i>
                  </div>
                </div>

                <div class="form-group form-group-feedback form-group-feedback-left">
                  <select class="form-control" required v-model="financialyear">
                    <option value="2000">2000</option>
                  </select>
                  <div class="form-control-feedback">
                    <i class="icon-calendar2 text-muted"></i>
                  </div>
                </div>

                <div class="form-group d-flex align-items-center">
                  <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" class="custom-control-input" id="cbremember" >
                    <label class="custom-control-label" for="cbremember">Remember</label>
                  </div>

                </div>


                <div class="form-group">
                  <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple btn-block" >Let's Go <i class="icon-circle-right2 ml-2"></i></button>
                </div>

              </form>
              <!-- /form -->

            </div>
          </div>
        </div>
        <!-- /validation form -->


      </div>
      <!-- /main content -->

    </div>
    <!-- /page content -->
  </FocusTrap>
</template>

<script>

import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import * as focusTrap from 'focus-trap';

export default {
  components: {
    // 'FocusTrap': FocusTrap,
  },
  store,
  data() {
    return {
      isActive: false,
      myyear : (new Date()).getFullYear(),
      branches:[],
      branch: {"id":1,"type":0,"name":"","street":"","city":"","pincode":"","state_cd":33,"contact_person":"","telephone":"","email":""},
      financialyear: 0,
      pageheader_visible: false,
      welcomemsg: 'Welcome, Admin!',
      loggedin: false,
      username: ''
    }
  },
  watch: {
    'store.state': function (newVal) {
    }
  },
  created() {
    // $('#applist').hide();
    // this.$data.loggedin = userService.isloggedIn();

  },
  mounted() {
    const self = this;


    // document.addEventListener('storage', this.logStorageChange);
    $(window).on('storage', function (e) {
      // console.log(e.originalEvent.key, e.originalEvent.newValue);
    });


    if (userService.isloggedIn()) {
      this.$data.loggedin= true;
      let profile =  JSON.parse(localStorage.getItem('lmsuser'));

      // let user = $cookies.get('tmsuser');
      store.state.user = profile;

      this.$data.welcomemsg = store.state.user.company.name;// + ' @ ' + store.state.user.finyear;

      this.$data.username = profile.name;

    }else{
      this.$data.loggedin= false;
      this.$router.push({ path: '/login' });
    }

  },
  methods: {

  }
}
</script>
