import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import NotFound from './components/NotFound.vue'
import Login from './views/Login.vue'
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
Vue.use(Router)

//https://stackoverflow.com/questions/64298276/load-dynamic-vue-routes-from-json-file
//https://router.vuejs.org/api/#router-addroutes
//https://router.vuejs.org/guide/advanced/dynamic-routing.html#Adding-routes-inside-navigation-guards

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    }, {
      path: '/login',
      name: 'login',
      component: Login
    }, {
      path: '/logout',
      name: 'logout',
      component: () => import('./views/Logout.vue')
    }, {
      path: '/profile',
      component: () => import('@/views/lms/user/UserProfile.vue')
    }, {
      path: '/reportdesinger',
      name: 'reportdesinger',
      component: () => import('./views/tools/Testing.vue')
    }, {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('./views/lms/dashboard/Dashboard.vue')
    },{
      path: '/ledgergroup',
      name: 'ledgergroup',
      component: () => import('./views/account/ledgergroup/LedgerGroupView.vue')
    }, {
      path: '/hr-designation',
      // name: 'hr-designation',
      component: () => import('./views/hr/designation/DesignationView.vue')
    }, {
      path: '/hr-emp',
      // name: 'hr-emp',
      component: () => import('./views/hr/employee/EmployeeView.vue')
    },{
      path: '/hr-shift',
      // name: 'hr-shift',
      component: () => import('@/components/v1/hr/shift/ShiftForm.vue')
    }, {
      path: '/companies',
      name: 'companies',
      component: () => import('./views/lms/company/CompanyView.vue')
    },{
      path: '/search_book',
      name: 'search_book',
      component: () => import('@/views/lms/search/BookSearchForm.vue'),
    },{
      path: '/library',
      name: 'library',
      component: () => import('@/views/lms/library/LibraryView.vue'),
    }, {
      path: '/department',
      name: 'department',
      component: () => import('@/views/lms/department/DepartmentView.vue'),
    },{
      path: '/academicyear',
      component: () => import('@/views/lms/academicyear/AcademicYearView.vue'),
    },{
      path: '/memberdue',
      component: () => import('@/views/lms/memberdue/MemberDue.vue'),
    },{
      path: '/section',
      name: 'section',
      component: () => import('@/views/lms/section/SectionView.vue'),
    },{
      path: '/book_group',
      name: 'book_group',
      component: () => import('@/views/lms/group/GroupView.vue'),
    },{
      path: '/booklist',
      name: 'booklist',
      component: () => import('@/views/lms/book/BookView.vue'),
    },{
      path: '/book_add',
      name: 'book_add',
      component: () => import('@/views/lms/book/BookForm.vue'),
    },{
      path: '/resource_add',
      name: 'resource_add',
      component: () => import('@/views/lms/book/ResourceForm.vue'),
    },{
      path: '/book_category',
      name: 'book_category',
      component: () => import('@/views/lms/category/CategoryView.vue'),
    },{
      path: '/book_author',
      name: 'book_author',
      component: () => import('@/views/lms/author/AuthorView.vue'),
    },{
      path: '/book_vendor',
      name: 'book_vendor',
      component: () => import('@/views/lms/vendor/VendorView.vue'),
    },{
      path: '/book_publisher',
      name: 'book_publisher',
      component: () => import('@/views/lms/publisher/PublisherView.vue'),
    },{
      path: '/member_staff',
      name: 'member_staff',
      component: () => import('@/views/lms/member/StaffView.vue'),
    },{
      path: '/member_student',
      name: 'member_student',
      component: () => import('@/views/lms/member/StudentView.vue'),
    },{
      path: '/newissue',
      name: 'newissue',
      component: () => import('@/views/lms/issue/IssueForm.vue'),
    },{
      path: '/openissue',
      name: 'openissue',
      component: () => import('@/views/lms/issue/IssueView.vue'),
    },{
      path: '/newreceipt',
      name: 'newreceipt',
      component: () => import('@/views/lms/receipt/ReceiptForm.vue'),
    },{
      path: '/openreceipt',
      name: 'openreceipt',
      component: () => import('@/views/lms/receipt/ReceiptView')
    },{
      path: '/pendinglist',
      name: 'pendinglist',
      component: () => import('@/views/lms/reports/books/pendinglist')
    },{
      path: '/report_book_general',
      name: 'report_book_general',
      component: () => import('@/views/lms/reports/books/General.vue')
    },{
      path: '/report_book_acquisition',
      name: 'report_book_acquisition',
      component: () => import('@/views/lms/reports/books/AcquisitionReport')
    },{
      path: '/report_book_acquisition_statistics',
      name: 'report_book_acquisition_statistics',
      component: () => import('@/views/lms/reports/books/AcquisitionStatistics')
    },{
      path: '/report_book_finance',
      name: 'report_book_finance',
      component: () => import('@/views/lms/reports/books/AcquisitionReport')
    },{
      path: '/member_list',
      name: 'member_list',
      component: () => import('@/views/lms/reports/member/member_list')
    },{
      path: '/report_student_list',
      name: 'report_student_list',
      component: () => import('@/views/lms/reports/member/StudentList.vue')
    },{
      path: '/report_staff_list',
      name: 'report_staff_list',
      component: () => import('@/views/lms/reports/member/StaffList.vue')
    },{
      path: '/report_maximum_users',
      name: 'report_maximum_users',
      component: () => import('@/views/lms/reports/MaximumUser.vue')
    },{
      path: '/staff_list',
      name: 'staff_list',
      component: () => import('@/views/lms/reports/member/StaffList.vue')
    },{
      path: '/department_wise_student',
      name: 'department_wise_student',
      component: () => import('@/views/lms/reports/member/DepartmentWiseStudentList.vue')
    },{
      path: '/c',
      name: 'book_general',
      component: () => import('@/views/lms/reports/books/General.vue')
    },{
      path: '/report_backvolume_general',
      name: 'report_backvolume_general',
      component: () => import('@/views/lms/reports/backvolume/General.vue')
    },{
      path: '/report_nonbook_general',
      name: 'report_nonbook_general',
      component: () => import('@/views/lms/reports/nonbook/General.vue')
    },{
      path: '/report_periodical_general',
      name: 'report_periodical_general',
      component: () => import('@/views/lms/reports/periodical/General.vue')
    },{
      path: '/report_periodical_statistics',
      name: 'report_periodical_statistics',
      component: () => import('@/views/lms/reports/periodical/PeriodicalStatistics.vue')
    },{
      path: '/report_resource_issues',
      name: 'report_resource_issues',
      component: () => import('@/views/lms/reports/circulation/IssueHistory.vue')
    },{
      path: '/report_resource_returns',
      name: 'report_resource_returns',
      component: () => import('@/views/lms/reports/circulation/ReturnHistory.vue')
    },{
      path: '/report_resource_overdue',
      name: 'report_resource_overdue',
      component: () => import('@/views/lms/reports/circulation/OverDue.vue')
    },{
      path: '/report_resource_issuesreturns',
      name: 'report_resource_issuesreturns',
      component: () => import('@/views/lms/reports/circulation/IssueReturnHistory.vue')
    },{
      path: '/report_resource_outstanding',
      name: 'report_resource_outstanding',
      component: () => import('@/views/lms/reports/circulation/OutStandingList.vue')
    },{
      path: '/deaprtment_wise_books',
      name: 'deaprtment_wise_books',
      component: () => import('@/views/lms/reports/books/DeptWiseBooks.vue')
    },{
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/hr/holidays/YearCalendar')
    },{
      path: '/subject',
      name: 'subject',
      component: () => import('@/views/lms/subject/SubjectView')
    },{
      path: '/periodicity',
      name: 'periodicity',
      component: () => import('@/views/lms/periodicity/PeriodicityView')
    },{
      path: '/backvolume',
      name: 'backvolume',
      component: () => import('@/views/lms/backvolume/BackVolumeView')
    },{
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/lms/settings/Settings')
    },{
      path: '/users',
      name: 'users',
      component: () => import('@/views/lms/user/UserForm')
    },{
      path: '/onlineusers',
      component: () => import('@/views/lms/user/OnlineUsers')
    },{
      path: '/*',
      name: 'not found',
      component: NotFound,
    }
  ],
  base: '/',
  fallback: true,
})
