import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vmodal from 'vue-js-modal'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VueCookies from 'vue-cookies'
import vSelect from 'vue-select'
import Cleave from "cleave.js";
import { FocusTrap } from 'focus-trap-vue'



Vue.config.productionTip = false
Vue.use(vmodal, { componentName: 'vmodal', dynamic: true, injectModalsContainer: true, draggable: true, resizable: true  })
Vue.use(VueMoment, {componentName: 'moment', dynamic: true, injectModalsContainer: true })
Vue.use(VueCookies)
Vue.component('v-select', vSelect)
// Vue.use(require('vue-shortkey'))
Vue.component('FocusTrap', FocusTrap)
// register globally

Vue.directive("cleave", {
  inserted: (el, binding) => {
    el.cleave = new Cleave(el, binding.value || {});
  },
  update: el => {
    el.value = el.cleave.properties.result;
    const event = new Event("input", { bubbles: true });
    el.dispatchEvent(event);
  }
});

new Vue({
  router,
  store,
  vmodal,
  VueCookies,
  render: h => h(App)
}).$mount('#app')
