<template>

</template>

<script>

import { store } from '@/store/store.js';
import { userService } from '@/store/auth-header.js';

export default {
  name: 'home',
  store,
  components: {
  },
  data () {
    return {

    }
  },
  created(){
    const self = this;

  },
  mounted() {
    const self = this;

  },
  methods:{

  }
}
</script>

<style>

  .panel {
    box-shadow: 0 2px 0 rgba(0,0,0,0.05);
    border-radius: 0;
    border: 0;
    margin-bottom: 24px;
  }

  .panel-dark.panel-colorful {
    background-color: #3b4146;
    border-color: #3b4146;
    color: #fff;
  }

  .panel-danger.panel-colorful {
    background-color: #f76c51;
    border-color: #f76c51;
    color: #fff;
  }

  .panel-primary.panel-colorful {
    background-color: #5fa2dd;
    border-color: #5fa2dd;
    color: #fff;
  }

  .panel-info.panel-colorful {
    background-color: #4ebcda;
    border-color: #4ebcda;
    color: #fff;
  }

  .panel-body {
    padding: 25px 20px;
  }

  .panel hr {
    border-color: rgba(0,0,0,0.1);
  }

  .mar-btm {
    margin-bottom: 15px;
  }

  h2, .h2 {
    font-size: 28px;
  }

  .small, small {
    font-size: 85%;
  }

  .text-sm {
    font-size: .9em;
  }

  .text-thin {
    font-weight: 300;
  }
</style>
